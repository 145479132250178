import axios from "axios";
import Cookies from "js-cookie";
import {
	LawyerUrl,
	articleUrl,
	loginUrl,
	managementUrl,
	signupUrl,
} from "../utils/urls";
import { displayErrorToast, displaySuccessToast } from "./toast";
export const getDataByLocation = (location: string, employees: any) => {
	const data = employees.filter(
		(el: any) => el.location.toLowerCase() === location.toLowerCase()
	);

	console.log(`Team by location(${location}):`, data);

	return data;
};

export const registerUser = async (data: any) => {
	try {
		const response = await axios.post(signupUrl, data);
		Cookies.set("user_auth_token", JSON.stringify(response.data.token), {
			expires: 12,
		});
		displaySuccessToast("Authentication successfull");
		return response;
	} catch (error: any) {
		displayErrorToast(error.response.data.message);
	}
};

export const loginUser = async (data: any) => {
	try {
		const response = await axios.post(loginUrl, data);
		Cookies.set("user_auth_token", JSON.stringify(response.data.token), {
			expires: 12,
		});
		displaySuccessToast("Authentication successfull");
		return response;
	} catch (error: any) {
		displayErrorToast(error.response.data.message);
	}
};

export const createManager = async (data: any, userAuth: string) => {
	try {
		await axios.post(`${managementUrl}add`, data, {
			headers: {
				"content-type": "application/json",
				authorization: userAuth,
			},
		});

		displaySuccessToast("Manager created successfully");
	} catch (error) {
		displayErrorToast("Something went wrong");
	}
};

export const createArticle = async (data: any, userAuth: string) => {
	try {
		await axios.post(articleUrl, data, {
			headers: {
				authorization: userAuth,
			},
		});
		displaySuccessToast("Articles xreated successfully");
	} catch (error) {
		displayErrorToast("Something went wrong");
	}
};

export const deleteManager = async (id: String, userAuth: string) => {
	try {
		const response = await axios.delete(`${managementUrl}/delete/${id}`, {
			headers: {
				authorization: userAuth,
			},
		});

		displaySuccessToast("Deleted successfully");

		return response.data.json;
	} catch (error) {
		console.error(error);

		displayErrorToast(`Something went wrong: ${error}`);
	}

	// const response = await axios.delete(`${managementUrl}/delete/${id}`, {
	// 	headers: {
	// 		authorization: userAuth,
	// 	},
	// }).then((res) => res.status === 200 ? displaySuccessToast("Deleted successfully") : res.status === 500 && displayErrorToast("Something went wrong"))
};

export const deleteArticle = async (id: String, userAuth: string) => {
	try {
		const response = await axios.delete(`${articleUrl}/delete/${id}`, {
			headers: {
				authorization: userAuth,
			},
		});
		displaySuccessToast("Deleted Successfully");
		return response.data.json;
	} catch (error) {
		displayErrorToast("Something went wrong");
	}
};

export const updateManager = async (
	data: any,
	userAuth: string,
	id: string
) => {
	try {
		const response = await axios.patch(`${managementUrl}edit/${id}`, data, {
			headers: {
				"content-type": "application/json",
				authorization: userAuth,
			},
		});

		displaySuccessToast("Manager updated successfully");
		return response.data;
	} catch (error) {
		displayErrorToast(`${error}`);
		console.error(error);
	}
};

export const updateArticle = async (
	data: any,
	userAuth: string,
	id: string
) => {
	try {
		return await axios.patch(`${articleUrl}/${id}`, data, {
			headers: {
				authorization: userAuth,
			},
		});
	} catch (error) {
		displayErrorToast("Something went wrong");
		console.error(error);
	}
};

export const createLawyers = async (data: any, userAuth: string) => {
	try {
		await axios.post(`${LawyerUrl}add`, data, {
			headers: {
				"content-type": "application/json",
				authorization: userAuth,
			},
		});

		displaySuccessToast("Lawyer created successfully");
	} catch (error) {
		displayErrorToast("Something went wrong");
	}
};

export const deleteLawyer = async (id: String, userAuth: string) => {
	try {
		const response = await axios.delete(`${LawyerUrl}delete/${id}`, {
			headers: {
				authorization: userAuth,
			},
		});

		displaySuccessToast("Deleted successfully");

		return response.data;
	} catch (error) {
		displayErrorToast(`Something went wrong: ${error}`);
		console.error(error);
	}
};

export const updateLawyers = async (
	data: any,
	userAuth: string,
	id: string
) => {
	try {
		const response = await axios.patch(`${LawyerUrl}edit/${id}`, data, {
			headers: {
				"context-type": "application/json",
				authorization: userAuth,
			},
		});

		displaySuccessToast("Lawyer updated successfully");
		return response.data;
	} catch (error) {
		displayErrorToast("Something went wrong when trying to edit lawyer");
		console.error(error);
	}
};
