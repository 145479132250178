import React from "react";
import Layout from "../components/Layout";
import { HiArrowLongRight } from "react-icons/hi2";
import { IoShareSocialOutline } from "react-icons/io5";
import Footer from "../components/Footer";
import useArticle from "../customs/useArticle";
import { articleUrl, base_url } from "../utils/urls";
import { useNavigate } from "react-router-dom";

const News = () => {
	const { data } = useArticle(articleUrl);

	const navigate = useNavigate();
	return (
		<Layout>
			<main>
				<section className="team__header news__bg">
					<div className="team__header--box locations__top-help">
						<h1 className="heading__main">news and articles</h1>
						<h2 className="news__text">Insights</h2>
					</div>
				</section>
				<section className="news__main">
					{data?.map((news: any) => (
						<div className="news__main-box">
							<main>
								<img
									className="news__main-img"
									src={`${base_url}/article-images/${news?.image}`}
									alt=""
								/>

								<h1 className="news__main-head">
									{news?.title}
								</h1>

								<p className="news__main-date">
									{news?.createdAt}
								</p>

								<p className="news__main-det">
									{news?.description?.slice(0, 100)}...
								</p>
							</main>

							<footer>
								<a
									href={`/news/detail/${news._id}`}
									className="news__main-link"
								>
									read more{" "}
									<HiArrowLongRight className="news__icon" />
								</a>
								{/* <div className="news__main-share">
									<IoShareSocialOutline className="news__icon2" />{" "}
									share
								</div> */}
							</footer>
						</div>
					))}
				</section>
				<Footer />
			</main>
		</Layout>
	);
};

export default News;
