import { useState } from "react";
import {
	IoClose,
	IoTrashOutline,
	IoClipboardOutline,
	IoPeopleOutline,
} from "react-icons/io5";

import Cookies from "js-cookie";
import { CTX } from "../contexts/GlobalContext";
import { useNavigate } from "react-router-dom";
import useManagersFetch from "../customs/useManagersFetch";
import { articleUrl, managementUrl } from "../utils/urls";
import { deleteArticle } from "../helpers/general";
import useArticle from "../customs/useArticle";

export const ArticleTab = () => {
	const { data, setData, isLoading } = useArticle(articleUrl);


	const { userAuth, logout, handleLawyerToEdit } = CTX();
	const [deletable, setDeletable] = useState<String>("");
	const [showDeleteModal, setShowDeleteModal] = useState(false);

	const navigate = useNavigate();

	const handleDeleteAction = (e: any, member: any) => {
		e.preventDefault();

		setShowDeleteModal(true);
		setDeletable(member?._id);
	};

	const handleEditAction = (e: any, article: any) => {
		e.preventDefault();
		handleLawyerToEdit(article);
		Cookies.set("articleToEdit", JSON.stringify(article));
		navigate(`/admin/article/edit/${article._id}`);
	};

	const closeDeleteModal = () => {
		setShowDeleteModal(false);
	};

	const deleteAnArticle = async () => {
		await deleteArticle(deletable, userAuth);

		setData(data.filter((article: any) => article._id !== deletable));
		setDeletable("");
		setShowDeleteModal(false);
	};

	return (
		<>
			<div className="admin__content">
				<div className="admin__content-box">
					<div className="icon_box">
						<IoPeopleOutline className="admin__content-icon" />
					</div>
					<h3>Articles</h3>
					<h2>{data && data.length}</h2>
				</div>

				<div className="admin__lawyers-table">
					<div className="admin__th">
						<div className="th1">Title</div>
						<div className="th3">Date</div>
						<div className="th2"></div>
					</div>

					{data?.length >= 1 ? (
						data?.map((article: any) => (
							<a
								href={`/news/detail/${article._id}`}
								className="table_link"
								key={article._id}
							>
								<div className="admin__td">
									<div className="td1">{article?.title}</div>

									<div className="td2">
										{article?.createdAt}
									</div>

									<div className="td-ext">
										<IoTrashOutline
											size="2rem"
											className="trash_i"
											onClick={(e: any) => {
												handleDeleteAction(e, article);
											}}
										/>
										<IoClipboardOutline
											size="2rem"
											className="edit_i"
											// @ts-ignore
											onClick={(e: any) =>
												handleEditAction(e, article)
											}
										/>
									</div>
								</div>
							</a>
						))
					) : (
						<div className="admin__empty">
							<p className="admin__empty-text">
								There are no management to display............
							</p>
						</div>
					)}
				</div>
			</div>

			<footer>
				<div
					className={`delete-modal-container ${
						showDeleteModal ? "show" : ""
					}`}
				>
					<div className="delete-modal">
						<div className="close-delete-modal">
							<IoClose
								className="icon"
								onClick={(e: any) => closeDeleteModal()}
							/>
						</div>

						<p>Are you sure you want to delete?</p>

						<div className="actions">
							<button onClick={(e: any) => closeDeleteModal()}>
								No
							</button>
							<button
								onClick={(e: any) => {
									deleteAnArticle();
								}}
							>
								Yes
							</button>
						</div>
					</div>
				</div>
			</footer>
		</>
	);
};
