import { useParams } from "react-router-dom";
import Layout from "../components/Layout";
import useArticleSingle from "../customs/useArticleSingle";
import { articleUrl, base_url } from "../utils/urls";

const NewsDetail = () => {
	const { id } = useParams();

	const { data } = useArticleSingle(`${articleUrl}/${id}`);

	return (
		<Layout>
			<>
				<header
					className="team__header"
					style={{
						backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${base_url}/article-images/${data?.image})`,
					}}
				>
					<div className="news__header-box ">
						<h1 className="news__mainn">{data?.title}</h1>
						<p className="news__text">KANU G. AGABI, CON, SAN</p>
					</div>
				</header>
				<section className="news__intro">
					<p>{data?.description}</p>

					{/* <p>The lawyer’s Pledge </p>
					<div className="news__intro-1">
						<p>I am a Lawyer,</p>
						<p>For whom the time has come</p>
						<p>To avail myself of all my talents</p>
						<p>As a minister in the temple of justice</p>
						<p>To aid in the search for truth and peace</p>
						<p>In the service of the nation and of humanity</p>
						<p>Through the instrument of law and equity</p>
						<p>So help me God.</p>
					</div>

					<div className="news__intro-2">
						<p>
							I thank you for the honour of addressing you on the
							prospects and challenges of building a career in the
							legal profession. I have no doubt whatsoever that
							you gave much thought to this matter before
							embarking on the study of law. It is often the case
							that we do not know beforehand what the study and
							practice of law entails before we embark on it. In
							my case it was the headmaster of my primary school
							who, in 1959, advised me to become a lawyer. Then I
							was only thirteen years old. I had never met a
							lawyer. The headmaster himself was not a lawyer. I
							had never been to a court of law. I had never even
							seen or read book.
						</p>
					</div>
					<div>
						<p>
							I never forgot the advise of the headmaster and so
							when in 1968 I was seeking admission into university
							I chose to study law. Fortunately I was admitted
							into the faculty of law of the university of Lagos
							from where I graduated in 1971 and was called to the
							bar in 1972.
						</p>
						<p>
							Since then I have done nothing other than law. I
							have been in it for forty-eight years now. They have
							been forty-eight hard years. That is how it is meant
							to be. Whatever comes to you easily is not yours. It
							is by thy sweat that ye shall live. As lawyers we
							are called to the house of mourning. As the Bible
							says it is better to go to the house of mourning
							than the house of feasting. And when you are there –
							when you are in the house of mourning – you are not
							to turn it into a house of feasting. That is what we
							have been accused of doing. We have come under
							suspicion of turning the house of sorrow, the house
							of anxiety, the house of misery into a house of
							feasting – not for those who called us to share in
							their sorrows and anxieties – but for ourselves.
							Arising from that, my first advise to you is this:
							keep your eyes open for the help that you can
							render. Do not go around with the mentality that you
							need assistance. With that kind of mentality you can
							never amount to much. Go about with the mentality
							that there is something that you can do for others.
							When you are of that mentality the Lord will always
							equip you to serve. When the Lord looks into your
							heart and sees that you have a disposition to give
							he will see to it that that disposition is
							fulfilled.
						</p>
						<p>
							Many of us live in despair. And that is a sin
							against hope. We believe that we cannot make it
							without a patron. We spend all our lives searching
							for a patron. More often than not we never find that
							patron until we perish in the search. But we do have
							a patron – the Almighty God himself who has ordained
							that when we seek we shall find; when we ask we
							shall receive; and when we knock he shall open unto
							us. He gave us dominion. Dominion over all things
							and over the entire earth. It is for us to exercise
							that dominion. We come under punishment when we
							refuse to exercise our dominion and prefer instead
							to live off the sweat and blood of others. It is not
							enough to exert yourself half-heartedly or
							indifferently. That will not do. Because thou hast
							neither been hot nor cold I will spew thee out of my
							mouth. As a lawyer you have to be passionate. If you
							are not succeeding it simply means that you are not
							striving enough.
						</p>
					</div>
					<div className="news__intro-3">
						<p>
							That brings me to the second class. Those of us who
							presume. We suffer, not from despair, but from
							presumption. We assume that all will be well just
							because we are lawyers. Just as there are many who
							presume that all will be well because they belong to
							one political party or another or just because the
							minister or the governor is a relation of theirs.
							That is yet another sin - against hope –
							presumption. As a lawyer you are not just going to
							succeed unless you read your books. And there are
							many things to be read. That is why you are called
							learned. As a lawyer your bend as an extension of
							your office. You lie there to sleep and to think.
							That is the attitude that the Lord rewards. And then
							the lawyer has to be prayerful. You are going
							nowhere without prayer. Unless the lord build the
							house they labour in vain that build it. Our lord
							jesus Christ enjoined us to pray without ceasing.
							You can take it from me that that injunction applies
							to lawyers more than to any other class of people.
							In this profession we have often to contend with the
							devil himself. How are you going to succeed unless
							you bring God in? pray for moral strength. Pray for
							mental and intellectual strength. Pray for spiritual
							strength. We pray that at a time when we live in
							fear we may find courage to confront the dangers
							that menace us on every side. We pray that in this
							age of corruption we may be honest. At a time when
							we seem determined to cast God out of our lives we
							pray that He may be the beginning and the end of our
							lives.
						</p>
					</div>
					<div className="news__intro-3">
						<p>
							We pray that as we look up to God for sympathy so we
							may look with sympathy on those who have entrust us
							with their lives. Let their faith in us be not
							disappointed as our faith in God is not
							disappointed. As God is faithful so let lawyers also
							be faithful. At a time of greed and selfishness may
							God grant us the knowledge that it is better to give
							than to receive, better to minister than to be
							ministered to, better to serve than to be served.
						</p>
					</div>
					<div className="news__intro-3">
						<p>
							You called me in the hope that I have something to
							say to you that will give you hope. In doing so you
							gave me hope yourselves. I have come, not because I
							have something new to tell you that you do not know
							already, but to express my gratitude for the hope
							that you give me yourselves. If I have any
							qualifications to address you they derive from the
							fact that I, myself, am a man who plunged into my
							future without any plans whatsoever. All my life I
							have been groping. And if there is anything
							remarkable about that, it is the fact that
							throughout, even in the deepest darkness, God has
							been present. Caught in that long midnight, I have
							come to appreciate the mercy and compassion of God.
							As a nation caught in this midnight, we must be
							consoled by the hope held out by that great African,
							Edward Blyden when he said; “God intends out of all
							this darkness to bring a great light, to rectify all
							these crooked things to the greater glory of His
							name and to the humbling of the pride and wisdom of
							man.
						</p>
					</div>
				</section>
				<section className="news__foot">
					<h2>time has changed nothing </h2>
					<p>
						Never before has the future seemed so unpredictable.
						Some say it is our economics which have failed. Some
						blame it on our politics. Yet others say it is the
						social system. There are those who blame it on the legal
						system. It is none of these. It is we who have failed.
						It is man that has failed. No economic theories, no
						political theories, no legal theories are going to save
						the nation until we purge our hearts and souls and
						return to God. Only by seeking first the kingdom of God
						and its righteousness shall all these other things be
						added unto us. Our Lord Jesus Christ was right when he
						said that bread alone would not suffice. At the time
						that he said so, the political, economic and social
						conditions of the world were very much the same as they
						are now. The 21st century is no different from the 1st
						century. Labour was exploited at that time just as it is
						exploited today. Israel was under a dictatorship just as
						we were under military rule and just as we are even now
						because the presidential system is actually a
						dictatorship under the constitution. Religion was on the
						decline at that time just as it is now. At that time
						wealth was in the hands of a few just as it is now. If
						bread alone did not suffice in the time of Christ, bread
						alone will not suffice now.
					</p> */}
				</section>
			</>
		</Layout>
	);
};

export default NewsDetail;
