export const aboutDataOne = [
  "Alternative Dispute Resolution",
  "Banking Law",
  "Commercial Law",
  "Communications Law",
  "Company Law",
  "Constituitional Law",
  "Corporate Law",
  "Criminal Law",
  "Debt Recovery",
  "Deed And Conveyance",
  "Election Law and Election Petition",
  "Entertainment Law",
  "Energy and Natural Resources Law",
  "Environmental Law",
  "Family Law",
  "Financial Services Law",
  "General Civil Litigation",
  "Insolvency",
  "Intellectual Property Law",
];
export const aboutDataTwo = [
  "International and Human Rights Law",
  "International Humanitarian Law",
  "International Law",
  "Labour Law",
  "Maritime and Cabbotage Law",
  "Legislative Drafting and Advice",
  "Mergers and Acquisitions",
  "Public Advocacy",
  "Regulatory Law Practice",
  "Security Law and Policy",
  "Taxation Law",
];
